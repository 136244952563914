.input-group-text {
    margin-right: 25px;
}

.participant-card {
    padding: 0px;
    max-width: 100%;
}

.button-row {
    margin-bottom: 20px;
}

.checkbox-understand-group {
    align-content: flex-start;
}

@media (max-width: 500px) {
    .gender-btn-group {
        display: grid;
    }
}